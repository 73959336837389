
.v-card {
    &--reveal,
    &--reveal-inner {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
    }
    &--reveal {
        height: 100%;
        color: #fff;
        background-color: rgba(0, 0, 0, 0.6);
    }
    &--reveal-inner {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 12px;
    }
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
    .v-card {
        &--reveal-inner {
            padding: 24px;
        }
    }
}
@media (min-width: 1024px) {
}
@media (min-width: 1200px) {
}
