
@media (min-width: 576px) {
}
@media (min-width: 768px) {
    .v-tabs--density-compact {
        ::v-deep {
            .v-tab {
                min-width: 200px;
            }
        }
    }
}
@media (min-width: 1024px) {
}
@media (min-width: 1200px) {
}
