
.v-dialog--img::v-deep {
    > .v-overlay__content {
        border-radius: 0;
        margin: 0;
        padding: var(--container-gutter);
        max-width: 100%;
        max-height: 100vh;
        width: auto;
        align-items: center;
        justify-content: center;
        overflow-y: hidden;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        > .v-dialog__inner {
            display: flex;
            flex-direction: column;
            max-width: 100%;
            max-height: 100%;
        }
    }
}
@media (min-width: 1464px) {
    .v-dialog--img::v-deep {
        > .v-overlay__content {
            max-width: calc(var(--container) + (var(--container-gutter) * 2));
        }
    }
}
