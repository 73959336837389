
.v-card.v-card--flat {
    .v-divider.primary {
        -webkit-transition: all ease-out 0.5s;
        -ms-transition: all ease-out 0.5s;
        transition: all ease-out 0.5s;
    }
    &:hover {
        .v-divider.primary {
            border-color: var(--v-grey-darken4) !important;
        }
    }
}
